require('./bootstrap');

require('alpinejs');
require('@tailwindcss/forms');

$('#payNow').click(function()
{
  $(this).attr('disabled', 'disabled');
  $(this).attr('value', 'Processing..');
  $('#paymentForm').submit();
});
